import LocaleContextProvider from "./src/contexts/LocaleContext";
import { QueryParamProvider } from "use-query-params";
import { ReachAdapter } from "use-query-params/adapters/reach";

const WrapQueryParams = ({ children }) => (
  <QueryParamProvider adapter={ReachAdapter}>{children}</QueryParamProvider>
);

export const WrapCommon = ({ children, props }) => {
  return (
    <WrapQueryParams>
      <LocaleContextProvider
        locale={props.pageContext.locale}
        pathname={props.location.pathname}
      >
        {children}
      </LocaleContextProvider>
    </WrapQueryParams>
  );
};
